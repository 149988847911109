var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{staticClass:"card-link",attrs:{"to":{
               name : 'Media',
                 params : {
                   id : _vm.videos.media_id,
                   wistiaId : _vm.videos.wistia_id,
                 }
             }}},[_c('img',{staticClass:"card-carousel-card-thumbnail",style:({width: _vm.cardWidthStyle + 'px', height: _vm.cardHeightStyle + 'px'}),attrs:{"src":_vm.videos.thumbnail}}),_c('div',{staticClass:"card-carousel-card-footer text-center"},[_c('p',[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.videos.media_title))+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }