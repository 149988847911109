<template>
  <router-link class="card-link"
               :to="{
                 name : 'Media',
                   params : {
                     id : videos.media_id,
                     wistiaId : videos.wistia_id,
                   }
               }">
    <img class="card-carousel-card-thumbnail"
         :src="videos.thumbnail"
         :style="{width: cardWidthStyle + 'px', height: cardHeightStyle + 'px'}"
    />
    <div class="card-carousel-card-footer text-center">
      <p> {{ videos.media_title | truncate }} </p>
    </div>
  </router-link>
</template>

<script>
  import FilterMixin from '@/mixins/FilterMixin';

  export default {
    name  : 'Card',
    props : {
      videos            : Object,
      columnItems       : Number,
      cardCarouselWidth : Number,
    },
    computed : {
      cardWidthStyle() {
        const gap = 18; // left and right margin 9px ea
        return (this.cardCarouselWidth / this.columnItems) - gap;
      },
      cardHeightStyle() {
        //16:10 ratio
        return this.cardWidthStyle ? this.cardWidthStyle * 0.625 : 0;
      },
    },
    mixins : [
      FilterMixin,
    ],
  }
</script>

<style lang="scss" scoped>
  @import '../../../assets/scss/components/user/common/card';
</style>
